import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "../style/Dashboard.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  IconButton,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import OpenPlanner from "../components/common/OpenPlannerAPI.js";
import UploadCard from "../components/Courses/UploadCard";
import FileProgressSnackBar from "../components/Courses/FileProgressSnackBar";
import { CirclePicker } from "react-color";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import EditIcon from "@mui/icons-material/Edit";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";

function ClassCard(props) {
  const [currentColor, setCurrentColor] = useState(props.color);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [titleText, setTitleText] = useState(props.name);
  const [inputText, setInputText] = useState(titleText);

  const togglePicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleEdit = () => setIsEditing(true);

  const handleSave = (id) => {
    setTitleText(inputText);
    setIsEditing(false);
    OpenPlanner.updateSyllabus(id, { title: inputText });
  };
  const handleCancel = () => {
    setInputText(titleText);
    setIsEditing(false);
  };

  const handleColorChange = (color, id) => {
    setCurrentColor(color.hex);
    OpenPlanner.updateSyllabus(id, { color: color.hex });
  };
  const navigate = useNavigate();

  return (
    <div style={{ display: "inline-block", margin: "20px" }}>
      <Card
        sx={{
          width: 345,
          height: 300,
          position: "relative",
        }}
      >
        <div style={{ position: "relative" }}>
          <CardMedia
            sx={{
              height: 200,
              backgroundColor: currentColor,
              position: "relative",
            }}
            alt={props.name}
          >
            <Tooltip title="Delete Course">
              <IconButton
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: currentColor,
                  padding: "5px",
                }}
              >
                <DeleteIcon
                  onClick={async () => {
                    console.log("Deleting syllabus", props.id);
                    await OpenPlanner.deleteSyllabus(props.id);
                    await props.reFetchEvents();
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit course events">
              <IconButton
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: currentColor,
                  padding: "5px",
                }}
              >
                <EditCalendarIcon
                  className="editCourses"
                  onClick={() => {
                    props.JoyRideMoveToNext(2);
                    navigate(props.link);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Change color">
              <IconButton
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "50px",
                  zIndex: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: currentColor,
                  padding: "5px",
                }}
                onClick={togglePicker}
              >
                <ColorLensIcon />
              </IconButton>
            </Tooltip>
          </CardMedia>
        </div>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isEditing ? (
            <>
              <TextField
                variant="outlined"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSave(props.id)}
                >
                  Save
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {/* Non-editable title */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between", // Space between title and edit icon
                  alignItems: "center", // Vertically align items
                }}
              >
                <Typography variant="h5" component="div" color="textPrimary">
                  {titleText}
                </Typography>
                <Tooltip title="Change course title">
                  <IconButton
                    sx={{
                      marginLeft: "10px",
                      color: "gray",
                    }}
                    onClick={handleEdit}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </CardContent>

        {displayColorPicker && (
          <div
            style={{
              position: "absolute",
              top: "60px",
              left: "10px",
              zIndex: 20,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CirclePicker
              color={currentColor}
              onChange={(color) => handleColorChange(color, props.id)}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default function Courses(props) {
  const [courses, setCourses] = React.useState([]);
  const [filesUploadState, setFilesUploadState] = React.useState([]);
  const [showFileProgress, setShowFileProgress] = React.useState(false);
  const [displayUploadCard, setDisplayUploadCard] = React.useState(true);
  async function fetchData() {
    setCourses(await OpenPlanner.getCourses(false));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [fileCheckingInterval, setFileCheckingInterval] = useState(null);
  useEffect(() => {
    if (fileCheckingInterval) {
      console.log("Clearing interval");
      clearInterval(fileCheckingInterval);
    }

    let interval = setInterval(() => {
      if (filesUploadState.length !== 0) {
        fetchData();
      }
    }, 1000);

    setFileCheckingInterval(interval);
    // Clear interval after 5 minute
    setTimeout(() => {
      clearInterval(interval);
    }, 1000 * 60 * 5);
  }, [filesUploadState]);

  useEffect(() => {
    //Check if course in Courses is the one being uploaded( filesUploadState)
    if (filesUploadState.length === 0) return;
    let copy = [...filesUploadState];
    filesUploadState.forEach((fileState) => {
      if (courses.find((course) => course.id === fileState.id)) {
        copy.find((file) => file.id === fileState.id).state = "Upload Complete";
      }
    });
    setFilesUploadState(copy);
    props.JoyRideMoveToNext(1);
  }, [courses]);

  useEffect(() => {
    props.setGlobalBox(
      <div style={{ visibility: showFileProgress ? "visible" : "hidden" }}>
        <FileProgressSnackBar
          handleClose={() => {
            setShowFileProgress(false);
          }}
          filesUploadState={filesUploadState}
        />
      </div>
    );
  }, [filesUploadState]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "left",
      }}
    >
      {courses.map((course, index) => {
        return (
          <ClassCard
            key={course.id}
            JoyRideMoveToNext={props.JoyRideMoveToNext}
            name={course.name}
            reFetchEvents={fetchData}
            color={course.color}
            link={`/Course/${course.id}`}
            id={course.id}
          />
        );
      })}

      <UploadCard
        disabled={!displayUploadCard}
        handleChange={async (file) => {
          setShowFileProgress(true);
          let fileState = {
            state: "Uploading",
            id: null,
            name: file.name,
          };
          setDisplayUploadCard(false);
          let copy = [...filesUploadState, fileState];
          let id = copy.length - 1;
          setFilesUploadState(copy);
          let response = await OpenPlanner.uploadSyllabus(file);
          copy = [...filesUploadState];
          copy[id] = {
            state: "Processing Syllabus",
            id: response.syllabusID,
            name: file.name,
          };
          setDisplayUploadCard(true);
          setFilesUploadState(copy);
        }}
      />

      <footer
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          textAlign: "center",
          padding: "10px",
        }}
      >
        While we strive to ensure the dates/events are accurate, we cannot
        guarantee their accuracy. Please double check the generated info.
      </footer>
    </Box>
  );
}
