import React, { useEffect, useRef } from "react";
import ToastCalendar from "@toast-ui/react-calendar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Checkbox,
  Button,
  IconButton,
  Grid2 as Grid,
  Collapse,
  Paper,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { isAllDayEvent } from "../components/common/EditEvent.js";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import Dialog from "@mui/material/Dialog";
import EditEvent from "../components/common/EditEvent.js";
import { Add, CompareSharp } from "@mui/icons-material";
import AddEvent from "../components/common/AddEvent.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ExportTutorial({ close }) {
  const handleExportCalendar = async () => {
    //Download the calendar from /calendar/export with axios
    OpenPlannerAPI.getExportICS().then((response) => {
      console.log(response, response.data);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calendar.ics");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <Paper sx={{ padding: 5 }}>
      <Typography variant="h4">Exporting your Calendar</Typography>
      <br />
      <Typography variant="h6">
        <b>Step 1</b>: Download the exported ics file here
        <br />
        <Button variant="contained" onClick={handleExportCalendar}>
          Download
        </Button>
      </Typography>
      <br />
      <Typography variant="h6">
        <b>Step 2</b>: <br />
        Open the downloaded file with your default calendar application.
      </Typography>
      <p>Double click on your existing file.</p>
      <br />
      or
      <br />
      <br />
      <Typography variant="h6">
        Import the downloaded file to your calendar.
      </Typography>
      <p>
        For instructions on importing the ICS file to a different online
        calendars, please visit the following links:{" "}
        <a
          target="_blank"
          href="https://support.apple.com/en-in/guide/calendar/icl1023/mac#:~:text=Import%20events%20from%20a%20calendar,calendar%20file%2C%20then%20click%20Import"
        >
          Apple
        </a>
        ,{" "}
        <a
          target="_blank"
          href="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
        >
          Outlook
        </a>
        ,{" "}
        <a
          target="_blank"
          href="https://support.google.com/calendar/answer/37118?hl=en&amp;co=GENIE.Platform%3DDesktop"
        >
          Google
        </a>
        .{" "}
      </p>
      <br />
      <Button
        variant="contained"
        onClick={() => {
          close();
        }}
      >
        Close
      </Button>
    </Paper>
  );
}
export default function Calendar(props) {
  const calendarRef = useRef(null);
  const [editEvent, setEditEvent] = React.useState(null);
  const [addEvent, setAddEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [hiddenCourses, setHiddenCourses] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [calendarView, setCalendarView] = React.useState("month");
  const [displayLeftBar, setDisplayLeftBar] = React.useState(true);
  const [displayExportTutorial, setDisplayExportTutorial] =
    React.useState(false);
  const fetchEvents = async () => {
    setEvents(await OpenPlannerAPI.getEvents(false));
    setCourses(await OpenPlannerAPI.getCourses(false));
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarInstance = calendarRef.current.getInstance();
      if (calendarInstance) {
        setYear(calendarRef.current.getInstance().getDate().getFullYear());
        calendarInstance.setOptions({
          template: {
            monthGrid(event) {
              console.log("Rendering event in monthGrid:", event);
              return `<span>${event.title || "No Title"}</span>`;
            },
            time(event) {
              return `<span>${event.title || "No Title"}</span>`;
            },
          },
        });
        calendarInstance.render(true);
      }
    }
  }, []);

  const handlePrevButton = () => {
    calendarRef.current.getInstance().prev();
    setMonth(calendarRef.current.getInstance().getDate().getMonth());
    setYear(calendarRef.current.getInstance().getDate().getFullYear());
  };
  const handleNextButton = () => {
    calendarRef.current.getInstance().next();
    setMonth(calendarRef.current.getInstance().getDate().getMonth());
    setYear(calendarRef.current.getInstance().getDate().getFullYear());
  };

  //Current time
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function LeftBar() {
    return (
      <div
        style={{
          minWidth: "320px",
          width: "320px",
          background: "white",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Dialog open={displayExportTutorial}>
          <ExportTutorial
            close={() => {
              setDisplayExportTutorial(false);
            }}
          />
        </Dialog>
        <Button
          variant="contained"
          sx={{ margin: 3, marginBottom: 1, background: "#670083" }}
          onClick={() => {
            setAddEvent({ start: new Date(), end: new Date() });
          }}
        >
          Add Event
        </Button>
        <Button
          onClick={() => {
            setDisplayExportTutorial(true);
          }}
          variant="contained"
          sx={{ margin: 3, marginTop: 1, background: "#670083" }}
        >
          Export Calendar
        </Button>
        <DateCalendar
          onChange={(e) => {
            calendarRef.current.getInstance().setDate(e);
            setMonth(e.month());
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 100,
              marginLeft: 10,
              marginBottom: 0,
              marginTop: "-30px",
            }}
          >
            My Classes
          </Typography>
          {courses.map((course) => {
            return (
              <div key={course.id} style={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={course.color}
                      checked={hiddenCourses.indexOf(course.id) === -1}
                      sx={{
                        color: course.color,
                        "&.Mui-checked": {
                          color: course.color,
                        },
                      }}
                      onClick={(e) => {
                        if (!e.target.checked) {
                          setHiddenCourses((hiddenCourses) => [
                            ...hiddenCourses,
                            course.id,
                          ]);
                        } else {
                          setHiddenCourses((hiddenCourses) =>
                            hiddenCourses.filter((id) => id !== course.id)
                          );
                        }
                      }}
                    ></Checkbox>
                  }
                  label={course.name}
                ></FormControlLabel>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  //https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  function colorIsDarkAdvanced(bgColor) {
    let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L <= 0.179;
  }

  return (
    <>
      {" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: "flex" }}>
          <Tooltip title="Hide Bar Menu">
            <IconButton
              style={{ height: 50, width: 50, margin: "0px 5px 0px 5px" }}
              onClick={() => {
                setDisplayLeftBar(!displayLeftBar);
              }}
            >
              <MenuIcon style={{ height: 30, width: 30 }} />
            </IconButton>
          </Tooltip>
          <img
            style={{ margin: 25, marginTop: 7, marginBottom: 0, height: 35 }}
            alt="OpenPlanner"
            src="Logo.png"
          ></img>
          <div style={{ width: "100%" }}>
            {editEvent != null && (
              <Dialog open={true} onClose={() => setEditEvent(null)}>
                <EditEvent
                  event={editEvent}
                  onSubmit={async () => {
                    fetchEvents();
                    setEditEvent(null);
                    //window.location.reload();
                  }}
                />
              </Dialog>
            )}
            {addEvent != null && (
              <Dialog open={true} onClose={() => setEditEvent(null)}>
                <AddEvent
                  event={addEvent}
                  courses={courses}
                  onSubmit={() => {
                    fetchEvents();
                    setAddEvent(null);
                  }}
                />
              </Dialog>
            )}
            <div
              style={{ height: "50px", display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outlined"
                sx={{ marginLeft: 2, marginRight: 2 }}
                onClick={() => {
                  calendarRef.current.getInstance().setDate(new Date());
                  setMonth(
                    calendarRef.current.getInstance().getDate().getMonth()
                  );
                  setYear(
                    calendarRef.current.getInstance().getDate().getFullYear()
                  );
                }}
              >
                Today
              </Button>
              <Tooltip title="Go to previous month">
                <IconButton onClick={handlePrevButton}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Go to next month">
                <IconButton onClick={handleNextButton}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Tooltip>

              <div
                style={{
                  display: "inline-block",
                  width: 200,
                }}
              >
                <Typography variant="h5" sx={{ marginLeft: 2 }} component="b">
                  {monthNames[month] + " "}
                  {year}
                </Typography>
              </div>

              <div style={{ marginLeft: "auto" }}></div>
              <Button
                onClick={() => {
                  setCalendarView("day");
                }}
              >
                Day
              </Button>
              <Button
                onClick={() => {
                  setCalendarView("week");
                }}
              >
                Week
              </Button>
              <Button
                onClick={() => {
                  setCalendarView("month");
                }}
              >
                Month
              </Button>

              <div style={{ width: 100 }}></div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Collapse orientation="horizontal" in={displayLeftBar}>
            <LeftBar />
          </Collapse>
          <div style={{ flex: 1 }}>
            <ToastCalendar
              ref={calendarRef}
              height="800px"
              view={calendarView}
              scrollToNow={true}
              navigation={true}
              useScrollDirection={true}
              usageStatistics={false}
              width="auto"
              onBeforeCreateSchedule={(e) => {
                console.log("beforeCreateSchedule", e);
                e.guide.clearGuideElement();
              }}
              onBeforeUpdateEvent={async ({ event, changes }) => {
                //Do not trust event or change time

                let correctEvent = await OpenPlannerAPI.getEvent(event.id);
                let newStart = new Date(correctEvent.start);
                let newEnd = new Date(correctEvent.end);

                // if start date are different, make sure distance to end is the same
                if (
                  changes.start &&
                  changes.start.d.getDate() != newStart.getDate()
                ) {
                  let diff = newEnd.getDate() - newStart.getDate();
                  newEnd.setDate(changes.start.d.getDate() + diff);
                  newStart.setDate(changes.start.d.getDate());
                } else {
                  if (changes.end) newEnd.setDate(changes.end.d.getDate());
                }

                //Cannot update to new month
                if (
                  new Date(correctEvent.start).getMonth() != newStart.getMonth()
                ) {
                  return false;
                }

                // // Update state
                setEvents((prevEvents) =>
                  prevEvents.map((evt) =>
                    evt.id === event.id
                      ? {
                          id: event.id,
                          start: newStart,
                          end: newEnd,
                          title: event.title,
                          body: event.body,
                          calendar: event.calendarId,
                          isCompleted: event.isPending,
                        }
                      : evt
                  )
                );

                await OpenPlannerAPI.updateEvent({
                  id: event.id,
                  start: newStart.toISOString(),
                  end: newEnd.toISOString(),
                  title: event.title,
                  body: event.body,
                  calendar: event.calendarId,
                  isCompleted: event.isPending,
                });
              }}
              onClickEvent={(e) => {
                setEditEvent({ calendar: e.event.calendarId, ...e.event });
              }}
              onSelectDateTime={(e) => {
                calendarRef.current.getInstance().clearGridSelections();
                setAddEvent({ start: e.start, end: e.end });
              }}
              events={events
                .filter((event) => {
                  return hiddenCourses.indexOf(event.calendar) === -1;
                })
                .map((event) => {
                  let allDay = isAllDayEvent(
                    new Date(event.end) - new Date(event.start)
                  );
                  return {
                    ...event,
                    start: new Date(event.start),
                    end: allDay ? event.start : new Date(event.end),
                    isAllday: allDay,
                    category: allDay ? "allday" : "time",
                    title: event.body,
                    customStyle: {
                      textDecoration: event.isCompleted
                        ? "line-through"
                        : "none",
                    },
                    calendarId: event.calendar,
                  };
                })}
              calendars={courses.map((course) => {
                return {
                  id: course.id,
                  name: course.name,
                  color: colorIsDarkAdvanced(course.color)
                    ? "#FFFFFF"
                    : "#000000",
                  backgroundColor: course.color,
                  dragBgColor: course.color,
                  borderColor: course.color,
                };
              })}
            />
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
}
