import React from "react";
import { Amplify } from "aws-amplify";
import { Link, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../style/Login.css";

export default function Login() {
  return (
    <div style={{ marginTop: 100, marginBottom: 100 }}>
      <div style={{ width: 480, margin: "auto" }}>
        <img width="480px" src="/Logo.png" alt="Logo"></img>
      </div>
      <Authenticator
        // socialProviders={["google"]}
        formFields={{
          signIn: {
            username: {
              label: "Username / Email",
            },
          },
          signUp: {
            email: {
              order: 1,
              label: "Email",
            },
            password: {
              order: 2,
            },
            confirm_password: {
              order: 3,
            },
            phone_number: {
              order: 4,
              label: "Phone Number (optional)",
              isRequired: false,
            },
          },
        }}
      >
        {({ signOut, user }) => (
          <main>
            <Navigate to="/Overview" />
          </main>
        )}
      </Authenticator>
    </div>
  );
}
