import React, { useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Dialog,
  ListItemText,
  Paper,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import EditEvent from "../common/EditEvent";
import OpenPlanner from "../common/OpenPlannerAPI";

export default function EventsList({ events, title, courses, reFetchEvents }) {
  const [editEvent, setEditEvent] = React.useState(null);
  function displayDate(date) {
    console.log(date);
    return (
      (date.getHours() % 12 == 0 ? 12 : date.getHours() % 12) +
      ":" +
      (date.getMinutes() == "0" ? "00" : date.getMinutes()) +
      " " +
      (date.getHours() <= 12 ? "AM" : "PM")
    );
  }
  return (
    <Box marginBottom={3}>
      {editEvent != null && (
        <Dialog open={true} onClose={() => setEditEvent(null)}>
          <EditEvent
            event={editEvent}
            onSubmit={() => {
              //window.location.reload();
              reFetchEvents();
              setEditEvent(null);
            }}
          />
        </Dialog>
      )}
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {events.length > 0 ? (
        events.map((event) => (
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setEditEvent(event);
            }}
            sx={{
              backgroundColor: event.isCompleted ? "darkgrey" : "#f1f1f1",
              "&:hover": {
                background: "#cbc9c9",
              },
              cursor: "pointer",
              minHeight: "70px",
              width: "100%",
              overflow: "hidden",
              alignItems: "stretch",
              marginBottom: "8px",
            }}
          >
            <Box
              sx={{
                padding: "20px 0 0 20px",
              }}
            >
              <Tooltip title="Mark as completed">
                <FormControlLabel
                  control={
                    <Checkbox
                      label="Mark as complete"
                      aria-label="Mark as completed"
                      checked={event.isCompleted}
                      sx={{ zIndex: 1 }}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await OpenPlanner.updateEvent({
                          ...event,
                          isCompleted: !event.isCompleted,
                        });
                        await reFetchEvents();
                      }}
                    ></Checkbox>
                  }
                />
              </Tooltip>
            </Box>
            <Box>
              <Typography
                sx={{
                  marginLeft: "16px",
                  color: "#000",
                  fontWeight: "bold",
                  width: 100,
                  padding: 1,
                }}
              >
                {displayDate(new Date(event.start))}{" "}
                {displayDate(new Date(event.end))}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: courses?.find(
                  (course) => course.id === event.calendar
                )?.color,
                width: "5px",
                minWidth: "5px",
                maxWidth: "5px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            ></Box>
            <Box
              alignItems="center"
              sx={{
                marginRight: "auto",
                color: "#000",
              }}
            >
              <Box sx={{ padding: 1 }}>
                <div
                  style={{
                    textDecoration: event.isCompleted ? "line-through" : "none",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: event.body.replace("\n", "<br/>"),
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="body1">
          You have no events due today. 🎉
        </Typography>
      )}
    </Box>
  );
}
