import React, { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsIcon from "@mui/icons-material/Notifications";
import OpenPlanner from "./OpenPlannerAPI.js";

function EditSingleNotification({
  onSave,
  onChange = () => {},
  eventID,
  notification,
}) {
  const [currentNotification, setCurrentNotification] = useState({
    unit: notification ? notification.timeUnit : "days",
    value: notification ? notification.time : 0,
  });

  useEffect(() => {
    if (notification)
      onChange(
        notification.notification_id,
        currentNotification.unit,
        currentNotification.value
      );
    else onChange(null, currentNotification.unit, currentNotification.value);
  }, [currentNotification]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentNotification((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    await OpenPlanner.deleteNotification(notification.notification_id);
    if (onSave) {
      await onSave();
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 1 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <NotificationsIcon />
        <Typography variant="body1">Notify me</Typography>{" "}
        <FormControl variant="outlined" sx={{ minWidth: 80 }} size="small">
          <InputLabel id="value-label">Value</InputLabel>
          <Select
            labelId="value-label"
            name="value"
            value={currentNotification.value}
            onChange={handleChange}
            label="Value"
          >
            {[
              ...Array(currentNotification.unit === "days" ? 8 : 24).keys(),
            ].map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 80 }} size="small">
          <InputLabel id="unit-label">Unit</InputLabel>
          <Select
            labelId="unit-label"
            name="unit"
            value={currentNotification.unit}
            onChange={handleChange}
            label="Unit"
          >
            <MenuItem value="days">days</MenuItem>
            <MenuItem value="hours">hours</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body1">before start date</Typography>{" "}
        {/* <IconButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleSave}
        >
          <SaveIcon />
        </IconButton> */}
        {notification && (
          <Tooltip title="Delete this event">
            <IconButton variant="outlined" color="error" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

/*
onChange takes in 3 parameters
        notification_id,
        currentNotification.unit,
        currentNotification.value

if notification_id is null, it means that the notification is new and should be added
*/
export default function EditNotifications({ eventID, onChange }) {
  const [notifications, setNotifications] = useState([]);
  const [showAddNotifications, setShowAddNotifications] = useState(false);

  const [notificationValues, setNotificationValues] = useState({});
  async function fetchData() {
    setNotifications(await OpenPlanner.getNotifications(eventID));
  }

  useState(() => {
    fetchData();
  }, []);

  useEffect(() => {
    onChange(notificationValues);
  }, [notificationValues]);

  async function onSave() {
    await fetchData();
    setShowAddNotifications(false);
  }

  const handleNotificationChange = async (notification_id, unit, value) => {
    let copy = Object.assign({}, notificationValues);
    copy[notification_id] = [unit, value];
    setNotificationValues(copy);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 1 }}
    >
      {notifications.map((notification, index) => (
        <EditSingleNotification
          onChange={handleNotificationChange}
          notification={notification}
          onSave={onSave}
        />
      ))}
      {showAddNotifications && (
        <EditSingleNotification
          onChange={handleNotificationChange}
          eventID={eventID}
          onSave={onSave}
        />
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          // If user click cancel, remove all new notifications
          if (showAddNotifications) {
            let copy = Object.assign({}, notificationValues);
            delete copy[null];
            setNotificationValues(copy);
          }
          setShowAddNotifications(!showAddNotifications);
        }}
      >
        {showAddNotifications ? "Cancel" : "Add Notification"}
      </Button>
    </Box>
  );
}
