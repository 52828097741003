import React, { useEffect, useState } from "react";
import { Snackbar, IconButton, LinearProgress, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, CardContent, Typography, CardMedia, Box } from "@mui/material";

export default function FileProgressSnackBar(props) {
  const [open, setOpen] = useState(true);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={
        <Box display="flex" flexDirection="column">
          {props.filesUploadState.map((file, index) => (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="white">
                {file.name}
              </Typography>
              {file.state !== "Upload Complete" && (
                <LinearProgress style={{ width: 200, marginTop: 8 }} />
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <span aria-live="polite">{file.state}</span>
              </Box>
            </Box>
          ))}
        </Box>
      }
      action={
        <Tooltip title="Close notification">
          <IconButton size="small" onClick={props.handleClose} color="inherit">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
