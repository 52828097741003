// App.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Grid2 as Grid,
  Paper,
  Box,
} from "@mui/material";

function Home() {
  //Redirect to https://platform.open-planner.com/login after 3 seconds
  setTimeout(() => {
    window.location.href = "https://open-planner.com";
  }, 100);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#fff",
          color: "#000",
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
            OpenPlanner
          </Typography>
          <Button href="/login" color="inherit">
            Sign In
          </Button>
        </Toolbar>
      </AppBar>
      <Paper>Loading...</Paper>
      <footer>
        <Paper
          sx={{
            textAlign: "center",
            padding: 2,
            bottom: 0,
            width: "100%",
            bgcolor: "#f8f8f8",
            borderTop: "1px solid #ddd",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            &copy; 2025 OpenPlanner
          </Typography>
        </Paper>
      </footer>
    </Box>
  );
}

export default Home;
