import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { signOut, updatePassword } from "aws-amplify/auth";

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  return (
    <Box>
      <Typography variant="h6" color="error">
        {error}
      </Typography>
      <Typography variant="h6" color="success">
        {success}
      </Typography>
      <TextField
        label="Old Password"
        value={oldPassword}
        type="password"
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <br />
      <br />
      <TextField
        label="New Password"
        value={newPassword}
        type="password"
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <br />
      <br />
      <TextField
        label="Confirm Password"
        type="password"
        onChange={(e) => {
          if (e.target.value !== newPassword) {
            setError("Passwords do not match");
          } else {
            setError("");
          }
        }}
      />
      <br />
      <br />
      <Button
        variant="contained"
        onClick={async () => {
          try {
            await updatePassword({ oldPassword, newPassword });
            setSuccess("Password changed successfully");
          } catch (error) {
            setError(error.message);
          }
        }}
      >
        Change Password
      </Button>
    </Box>
  );
}

export default function Settings() {
  return (
    <Box>
      <Box sx={{ padding: "30px" }}>
        <h3>Change your password</h3>
        <ChangePassword />
        <br />
        <Button
          variant="contained"
          onClick={async () => {
            await signOut();
            window.location = "https://open-planner.com";
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
}
