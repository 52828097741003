import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Box, Typography, Button, Dialog } from "@mui/material";
import EventsList from "../components/Overview/EventsList.js";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import CourseLegend from "../components/Overview/CourseLegend.js";
import sortEventsByDay from "../components/common/sortEventsByDay.js";
import AddEvent from "../components/common/AddEvent.js";
import DeleteCourseButton from "../components/common/DeleteCourseButton.js";
const formatDueDate = (dateString) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default function Course() {
  const [events, setEvents] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [displaySyllabus, setDisplaySyllabus] = React.useState(true);
  const [showAllPastEvents, setShowAllPastEvents] = React.useState(false);
  const [addEventForm, setAddEventForm] = React.useState(false);
  const syllabusID = useParams().id;
  const [iframeURL, setIframeURL] = React.useState(null);
  const fetchEvents = async () => {
    setIframeURL(
      encodeURIComponent(await OpenPlannerAPI.getSignedURL(syllabusID))
    );
    let data = await OpenPlannerAPI.getCourseEvents(syllabusID);
    const today = new Date();

    data.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return Math.abs(dateA - today) - Math.abs(dateB - today);
    });
    if (data) setEvents(data);
  };
  useEffect(() => {
    fetchEvents();

    const fetchCourses = async () => {
      let data = await OpenPlannerAPI.getCourses();
      if (data) setCourses(data);
      setLoading(false);
    };
    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const eventsByDay = sortEventsByDay(events);
  let pastEvents = events.filter((event) => new Date(event.start) < new Date());
  if (!showAllPastEvents) {
    pastEvents = pastEvents.slice(0, 3);
  }
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h4" gutterBottom sx={{ paddingRight: "50px" }}>
          {courses.find((course) => course.id === syllabusID)?.name}
        </Typography>
        <Box>
          {displaySyllabus ? (
            <Button
              variant="contained"
              onClick={() => setDisplaySyllabus(!displaySyllabus)}
            >
              Hide Syllabus
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setDisplaySyllabus(!displaySyllabus)}
            >
              Show Syllabus
            </Button>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            paddingRight: 3,
            width: "100%",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <EventsList
            reFetchEvents={fetchEvents}
            events={
              eventsByDay.find(
                (events) =>
                  new Date(events[0].start).toDateString() ===
                  new Date().toDateString()
              ) || []
            }
            title="Due Today"
            courses={courses}
          />
          {eventsByDay.map((events, index) => {
            if (
              new Date(events[0].start).toDateString() ===
              new Date().toDateString()
            )
              return null;
            return (
              <EventsList
                events={events}
                reFetchEvents={fetchEvents}
                courses={courses}
                title={`Due on ` + formatDueDate(events[0].start)}
              />
            );
          })}

          <EventsList
            reFetchEvents={fetchEvents}
            events={pastEvents}
            title="Past Events"
            courses={courses}
          />
          <Button
            onClick={() => {
              setShowAllPastEvents(!showAllPastEvents);
            }}
          >
            {showAllPastEvents ? "Show less events" : "Show all events"}
          </Button>
          <Dialog open={addEventForm} onClose={() => setAddEventForm(false)}>
            <AddEvent
              calendar={syllabusID}
              onSubmit={() => {
                fetchEvents();
                setAddEventForm(null);
              }}
            />
          </Dialog>
          <Button
            onClick={() => {
              setAddEventForm(!addEventForm);
            }}
          >
            Add Event
          </Button>
          <DeleteCourseButton id={syllabusID} />
        </Box>
        {displaySyllabus && (
          <Box sx={{ width: "100%", height: "100vh" }}>
            {iframeURL && (
              <iframe
                title="Syllabus PDF"
                width="100%"
                height="100%"
                src={"/pdfjs/web/viewer.html?file=" + iframeURL}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
