import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import OpenPlanner from "./OpenPlannerAPI.js";
import convertToDateTimeLocal from "./convertToDateTimeLocal";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import DeleteEventButton from "./DeleteEventButton";
import WYSIWYG, {
  Editor,
  EditorProvider,
  Toolbar,
  BtnBold,
} from "react-simple-wysiwyg";
import EditNotifications from "./EditNotifications";

// Between 11:59 and 12:01
export function isAllDayEvent(ms) {
  console.log(ms);
  return ms >= 86340000 && ms <= 86460000;
}

function ShareEvent({ event }) {
  return (
    <add-to-calendar-button
      name={event.body}
      startDate={event.start}
      startTime={event.start}
      endTime={event.end}
      endDate={event.end}
      timeZone="America/Los_Angeles"
      description={event.title}
      options="'Apple','Google','Outlook.com'"
      buttonsList
      hideTextLabelButton
      buttonStyle="round"
      lightMode="bodyScheme"
    ></add-to-calendar-button>
  );
}

export default function EditEvent({ event, onSubmit }) {
  const [formData, setFormData] = useState({
    id: event.id,
    start: convertToDateTimeLocal(event.start),
    end: convertToDateTimeLocal(event.end),
    title: event.title,
    body: event.body,
    calendar: event.calendar,
    isCompleted: event.isCompleted,
  });
  const [syllabusTitle, setSyllabusTitle] = useState();
  let totalHours = new Date(event.end) - new Date(event.start);

  const [isAllDay, setIsAllDay] = useState(isAllDayEvent(totalHours));
  // Load from event id rather than event object
  // Reason being sometimes the event object is modified incorrectly by toastui before being passed to this component
  // This is a workaround to ensure the event object is correct

  useEffect(() => {
    const fetchEvent = async () => {
      let newEvent = await OpenPlanner.getEvent(event.id);
      console.log(newEvent);
      setFormData({
        id: newEvent.id,
        start: convertToDateTimeLocal(newEvent.start),
        end: convertToDateTimeLocal(newEvent.end),
        title: newEvent.title,
        body: newEvent.body,
        calendar: newEvent.calendar,
        isCompleted: newEvent.isCompleted,
      });
    };
    fetchEvent();
    const fetchSyllabus = async () => {
      let newSyllabus = await OpenPlanner.getSyllabus(formData.calendar);
      setSyllabusTitle(newSyllabus.title);
    };
    fetchSyllabus();
  }, [event]);

  const [notificationsValues, setNotificationsValues] = useState({});

  if (!event) return null;
  const handleEndChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newEnd = value;
    if (new Date(value) < new Date(formData.start)) {
      newEnd = convertToDateTimeLocal(
        new Date(new Date(formData.start).getTime() + 1 * 60000)
      );
    }
    setIsAllDay(isAllDayEvent(new Date(value) - new Date(formData.start)));
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : newEnd,
    }));
  };

  const UpdateNotifications = async () => {
    for (const notificationID of Object.keys(notificationsValues)) {
      let unit = notificationsValues[notificationID][0];
      let value = notificationsValues[notificationID][1];
      if (notificationID == "null") {
        await OpenPlanner.addNotification(event.id, value, unit);
      } else {
        await OpenPlanner.updateNotification(notificationID, value, unit);
      }
    }
  };
  const handleStartDate = (e) => {
    const { name, value } = e.target;
    // If the end date is before the start date, set the end date to the start date plus 30 min
    let newEnd = formData.end;
    if (new Date(formData.end) < new Date(value)) {
      newEnd = convertToDateTimeLocal(
        new Date(new Date(value).getTime() + 30 * 60000)
      );
    }
    setFormData((prevData) => ({
      ...prevData,
      start: value,
      end: newEnd,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form
    await OpenPlanner.updateEvent({
      ...formData,
      start: new Date(formData.start).toISOString(),
      end: new Date(formData.end).toISOString(),
    });
    await UpdateNotifications();
    setFormData({
      id: "",
      start: "",
      end: "",
      title: "",
      body: "",
      calendar: "",
      isCompleted: false,
    });
    onSubmit();
  };

  const handleTitle = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      title: e.target.value,
    }));
  };
  const handleBody = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      body: e.target.value,
    }));
  };

  return (
    <Box
      sx={{ padding: 3, maxWidth: 600, margin: "0 auto", position: "relative" }}
    >
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          fontSize: "0.9rem",
          color: "#670083",
        }}
      >
        {syllabusTitle?.length > 30
          ? syllabusTitle.substr(0, 30) + "..."
          : syllabusTitle}
      </Typography>

      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>

      <form onSubmit={handleSubmit}>
        {!isAllDay && (
          <>
            <TextField
              label="Start"
              name="start"
              type="datetime-local"
              value={formData.start}
              onChange={handleStartDate}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="End"
              name="end"
              type="datetime-local"
              value={formData.end}
              onChange={handleEndChange}
              fullWidth
              margin="normal"
              required
            />
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllDay}
              onClick={() => {
                if (!isAllDay) {
                  setFormData((prevData) => ({
                    ...prevData,
                    start: formData.start.split("T")[0] + "T00:00",
                    end: formData.start.split("T")[0] + "T23:59",
                  }));
                }
                setIsAllDay(!isAllDay);
              }}
            />
          }
          label="All day event"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isCompleted ? true : false}
              onClick={() =>
                setFormData((prevData) => ({
                  ...prevData,
                  isCompleted: !formData.isCompleted,
                }))
              }
            />
          }
          label="Mark as completed"
        ></FormControlLabel>
        <br />
        Title
        <EditorProvider>
          <Editor value={formData.body} onChange={handleBody}></Editor>
        </EditorProvider>
        Optional Notes
        <EditorProvider>
          <Editor
            containerProps={{
              style: {
                minHeight: "20px",
              },
            }}
            value={formData.title}
            onChange={handleTitle}
          ></Editor>
        </EditorProvider>
        <EditNotifications
          onChange={setNotificationsValues}
          eventID={event.id}
        />
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Tooltip title="Save event information">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Tooltip>
          &nbsp;
          <DeleteEventButton onDelete={onSubmit} id={event.id} />
          <Button onClick={onSubmit}>Cancel</Button>
          <div style={{ marginRight: 100 }}></div>
          <ShareEvent event={formData} />
        </Box>
      </form>
    </Box>
  );
}
